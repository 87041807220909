<template>
  <section>
    <b-overlay :show="loading" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <b-row>
        <b-col cols="12">
          <div class="text-right">
            <b-button
                @click="newPostModal = true"
                class="share-button"
                size="sm"
                :disabled="loading"
                variant="success">
              <feather-icon size="20" icon="PlusIcon" />
              {{ $t('Share') }}
            </b-button>
          </div>
        </b-col>
        <b-col
          cols="12"
          offset-lg="3"
          offset-md="2"
          md="8"
          lg="6">
          <div v-if="data.length > 0">
            <div v-for="(post, index) in data" :key="index" class="moments-post">
              <div class="moments-header">
                <div class="moments-profile">
                  <div class="moments-avatar">
                    <b-avatar
                        size="66"
                        :src="post.profile_photo_url"
                        variant="light-primary"
                    />
                  </div>
                  <div class="moments-username-follow-button">
                    <div class="moments-username">
                      {{ post.user_name }}, {{ post.age }}
                    </div>
                    <div v-if="userId !== post.uuid" class="moments-follow-button">
                      <b-badge
                          @click="toggleFollow(post)"
                          pill
                          :variant="post.is_followed ? 'dark' : 'success'"
                          class="cursor-pointer"
                      >
                        {{ post.is_followed ? $t('Unfollow') : $t('Follow') }}
                      </b-badge>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column align-items-end justify-content-between gap-12">
                  <div v-show="userId !== post.uuid" class="dropdown">
                    <b-dropdown
                        variant="link"
                        no-caret
                        toggle-class="p-0"
                        right
                    >
                      <template #button-content>
                        <b-img :src="imageMore" class="cursor-pointer" width="18" />
                      </template>
                      <b-dropdown-item :to="{name: 'users.show', params: {id: post.uuid}}">
                        {{ $t('View profile') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="blockUser(post.uuid)">
                        {{ $t('Block') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="showReportModal(post)">
                        {{ $t('Report') }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="moments-time">
                    {{ getDate(post.created_at) }}
                  </div>
                </div>
              </div>
              <div class="moments-body">
                <div class="moments-media">
                  <div :id="'moments-media-' + post.id" style="width: 100%" v-viewer>
                    <b-img v-if="post.media_type === 'image'" :src="post.media_url" style="object-fit: contain" />
                    <b-embed
                        v-if="post.media_type === 'video'"
                        :src="post.media_url"
                        type="video"
                        preload="auto"
                        controls
                    />
                  </div>
                  <font-awesome-icon
                      v-if="post.media_type === 'image'"
                      @click="showMedia('#moments-media-' + post.id)"
                      :icon="['fas', 'maximize']"
                  />
                </div>
              </div>
              <div class="moments-footer">
                <div class="moments-content">
                  {{ post.content }}
                </div>
                <div class="moments-footer-sub">
                  <div class="moments-likes">
                    <font-awesome-icon @click="toggleLike(post)"
                                       class="moments-likes-icon"
                                       :icon="!post.is_liked ? 'fa-regular fa-heart' : 'fa-solid fa-heart'" />
                    <span class="moments-likes-count">
                      {{ post.like_count.toLocaleString() }}
                    </span>
                  </div>
                  <div class="moments-footer-menu">
                    <b-navbar-nav v-if="post.uuid === userId" class="nav align-items-center ml-auto">
                      <b-nav-item-dropdown
                          id="dropdown-grouped"
                          variant="link"
                          class="dropdown-language"
                          right
                      >
                        <template #button-content>
                          <font-awesome-icon :icon="['fas', 'ellipsis']" />
                        </template>
                        <b-dropdown-item>
                        <span @click="remove(post)" class="ml-50">
                          {{ $t('Remove') }}
                        </span>
                        </b-dropdown-item>
                      </b-nav-item-dropdown>
                    </b-navbar-nav>
                  </div>
                </div>
              </div>
              <div class="moments-hr"></div>
            </div>
          </div>
          <div v-else class="text-center mt-2">
            <div>
              <h3 class="color-black">
                {{ $t('No moments title') }}
              </h3>
            </div>
            <b-img fluid :src="imageDiscoverNoUser" />
          </div>
        </b-col>
      </b-row>
    </b-overlay>

    <b-modal
        v-model="newPostModal"
        modal-class="new-post-modal"
        size="lg"
        centered hide-footer>
      <div v-if="!file" @click="$refs.file.click()" class="new-post-modal-upload">
        <input type="file" ref="file" accept="image/*,video/*" @change="onFileChange" style="display: none;" />
        <div>
          <b-img :src="imagePhoto" class="mr-1" />
          <b-img :src="imageVideo" />
        </div>
        <div>
          {{ $t('Upload photo or video') }}
        </div>
      </div>
      <div v-else class="new-post-modal-preview">
        <div id="new-post-modal-viewer" v-viewer>
          <img v-if="fileType === 'image'" :src="file" />
          <b-embed
              v-if="fileType === 'video'"
              :src="file"
              type="video"
              controls
          />
        </div>
        <font-awesome-icon
            @click="file = null"
            :icon="['fas', 'xmark']"
        />
      </div>
      <b-form-textarea
          id="content"
          :disabled="loading"
          v-model="content"
          :placeholder="$t('Enter your text here') + '. (' + $t('Max 100 characters') + '.)'"
          class="mt-1"
          style="border-radius: 10px"
          rows="3"
          max-rows="6"
      ></b-form-textarea>
      <div class="form-accepted mt-1">
        {{ $t('New post checkbox text') }}
        <b-form-checkbox
            v-model="formAccepted"
            name="check-button"
        />
      </div>
      <div class="d-flex justify-content-center mt-2">
        <b-button
            @click="publish"
            variant="outline-success"
            :disabled="!file || !formAccepted">
          {{ $t('Publish') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal v-if="selectedPost" v-model="modalReportUser" :title="$t('Report {name}', {name: selectedPost.user_name})"
             centered hide-header-close hide-footer>
      <div>
        <validation-observer
            v-if="reportReasonTypes.length"
            ref="reportForm"
            #default="{invalid}"
        >
          <b-form
              class="mt-2"
              @submit.prevent
          >

            <b-form-group
                label-for="reason"
                :label="$t('Report reason')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Report reason')"
                  vid="reason"
                  rules="required"
              >
                <v-select
                    v-model="report.reason"
                    :clearable="false"
                    :disabled="loading"
                    :label="$t('Report reason')"
                    :options="reportReasonTypes"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
                label-for="reportMessage"
                :label="$t('Your message')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Your message')"
                  vid="description"
                  rules="required"
              >
                <b-form-textarea
                    id="description"
                    :disabled="loading"
                    v-model="report.description"
                    :placeholder="$t('Your message')"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
                variant="primary"
                class="mt-2 px-4"
                :disabled="loading"
                @click="reportUser"
            >
              <b-spinner v-if="loading" small></b-spinner>
              {{ $t('Report') }}
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BDropdownItem,
  BEmbed,
  BFormCheckbox,
  BFormTextarea,
  BImg,
  BMedia,
  BModal,
  BNavItemDropdown,
  BNavbarNav,
  BOverlay,
  BRow,
  BTabs,
  BTab, BDropdown, BSpinner, BFormGroup, BForm,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { gtmTrackNewEvent, showNotify, throwDefaultError } from '@/helpers/helpers'
import DiscoverNoUser from '@/components/DiscoverNoUser'
import moment from 'moment'
import 'moment/locale/es'
import GiftCard from '@/components/GiftCard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

// import { getUser } from '@/auth/utils'

export default {
  name: 'Moments',
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCol,
    BDropdown,
    BDropdownItem,
    BEmbed,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BImg,
    BMedia,
    BModal,
    BNavbarNav,
    BNavItemDropdown,
    BOverlay,
    BRow,
    BSpinner,
    BTabs,
    BTab,

    vSelect,

    DiscoverNoUser,
    GiftCard,

    ValidationObserver,
    ValidationProvider,
  },
  data() {
    const that = this

    return {
      content: '',
      data: [],
      fileRaw: null,
      file: null,
      fileMaxSize: 1024 * 1024 * 100,
      fileType: null,
      formAccepted: false,
      imageDiscoverNoUser: require('@/assets/images/pages/discover-no-user.png'),
      imageMore: require('@/assets/images/icons/more-horizontal-black.svg'),
      imagePhoto: require('@/assets/images/icons/camera.png'),
      imageVideo: require('@/assets/images/icons/video.png'),
      limit: 10,
      loading: false,
      modalReportUser: false,
      newPostModal: false,
      offset: 0,
      postsAllLoaded: false,
      removeModalOptions: {
        title: this.$t('Post deletion'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('Remove'),
        cancelTitle: this.$t('Cancel'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      },
      report: {
        reason: '',
        description: '',
      },
      scrollOffsetForLoadMore: 100,
      selectedPost: null,
      toRemoveData: null,
    }
  },
  created() {
    this.getMoments()

    // Scroll'u dinle
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    // Scroll'u dinlemeyi bırak
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    reportReasonTypes() {
      return this.$store.getters['app/reportReasonTypes']
    },
    userId() {
      return this.$store.getters['auth/userId']
    }
  },
  methods: {
    blockUser(id) {
      this.loading = true

      useJwt.blockUser(id)
          .then(response => {
            showNotify(this.$toast, this.$t('User blocking successful'), 'success')

            gtmTrackNewEvent('blockuser', {
              category: 'Profil',
              action: 'BlockUser',
              label: id
            })

            this.getMoments()
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    getDate(date) {
      moment.locale(localStorage.getItem('locale') || 'tr')
      return moment(date).fromNow()
    },
    getMoments(clear = true) {
      if (clear) {
        this.data = []
        this.offset = 0
        this.postsAllLoaded = false
      }

      this.loading = true
      useJwt.getPosts(this.offset, this.limit)
          .then(response => {
            const _data = response.data.body || []

            if (clear) {
              this.data = _data
            } else {
              this.data.push(..._data)
            }

            // Offset ayarla
            this.offset += this.limit

            if (_data.length < this.limit) {
              this.postsAllLoaded = true
            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    handleScroll (event) {
      if ((window.innerHeight + window.scrollY + this.scrollOffsetForLoadMore) >= document.body.scrollHeight &&
          !this.postsAllLoaded) {
        this.loadMore()
      }
    },
    loadMore() {
      if (!this.loading) {
        this.getMoments(false)
      }
    },
    publish() {
      this.loading = true

      try {
        let formData = new FormData();
        formData.append('file', this.fileRaw)
        formData.append('content', this.content)

        useJwt.newPost(formData)
            .then(response => {
              const _message = this.$t('Post created successfully') + '. ' +
                  this.$t('The post will be published after approval') + '.'
              showNotify(this.$toast, _message, 'success')
              this.newPostModal = false
              this.file = null
              this.fileRaw = null
              this.fileType = null
              this.content = ''
            })
            .catch(error => {
              throwDefaultError(this.$toast, error)
            })
            .finally(() => this.loading = false)
      } catch (e) {
        this.loading = false
      }
    },
    remove(post) {
      if (post && post.id) {
        this.$bvModal.msgBoxConfirm(this.$t('Are you sure you want to delete?'), this.removeModalOptions)
            .then(value => {
              if (value) {
                this.loading = true

                useJwt.deletePost(post.id)
                    .then(response => {
                      this.getMoments()
                      showNotify(this.$toast, this.$t('Deleted successfully'), 'success')
                    })
                    .catch(error => {
                      throwDefaultError(this.$toast, error)
                    })
                    .finally(() => { this.loading = false })
              }
            })
      }
    },
    reportUser() {
      this.$refs.reportForm.validate().then(success => {
        if (success) {
          this.loading = true
          const data = {
            reportee: this.$store.getters['auth/userName'],
            reporteeEmail: this.$store.getters['auth/userEmail'],
            reported: this.selectedPost.user_name,
            reason: this.report.reason,
            description: this.report.description,
          }
          useJwt.reportUser(data)
              .then(response => {
                showNotify(this.$toast, this.$t('Reporting user successful'), 'success')
                this.modalReportUser = false
                this.report = {
                  reason: '',
                  description: '',
                }

                gtmTrackNewEvent('reportuser', {
                  category: 'Profil',
                  action: 'ReportUser',
                  label: this.selectedPost.uuid
                })

                this.selectedPost = null

                this.getMoments()
              })
              .catch(error => {
                throwDefaultError(this.$toast, error)
              })
              .finally(() => {
                this.loading = false
              })
        }
      })
    },
    showMedia(id) {
      console.log(id, this.$el.querySelector(id))
      let viewer = this.$el.querySelector(id)
      if (viewer) {
        viewer.$viewer.show()
      }
    },
    showReportModal(post) {
      this.modalReportUser = true
      this.selectedPost = post

      gtmTrackNewEvent('reportclick', {
        category: 'Profil',
        action: 'ReportClick',
        label: post.uuid
      })
    },
    setLoading($event) {
      this.loading = $event
    },
    toggleFollow(post) {
      this.loading = true

      if (!post.is_followed) {
        useJwt.addFavoriteUser(post.uuid)
            .then(response => {
              showNotify(this.$toast, this.$t('Liking successful'), 'success')

              gtmTrackNewEvent('addfavorite', {
                category: 'Profil',
                action: 'AddFavorite',
                label: post.uuid
              })

              this.getMoments()
            })
            .catch(error => {
              throwDefaultError(this.$toast, error)
            })
            .finally(() => { this.loading = false })
      } else {
        useJwt.removeFavoriteUser(post.uuid)
            .then(response => {
              showNotify(this.$toast, this.$t('Unliking successful'), 'success')

              gtmTrackNewEvent('removefavorite', {
                category: 'Profil',
                action: 'RemoveFavorite',
                label: post.uuid
              })

              this.getMoments()
            })
            .catch(error => {
              throwDefaultError(this.$toast, error)
            })
            .finally(() => { this.loading = false })
      }
    },
    toggleLike(post) {
      this.loading = true

      let _request = null

      if (post.is_liked) {
        _request = useJwt.likePostDelete(post.id)
      } else {
        _request = useJwt.likePost({
          post_id: post.id
        })
      }

      if (_request) {
        _request
            .then(response => {
              post.is_liked = !post.is_liked
              post.like_count = post.is_liked ? post.like_count + 1 : post.like_count - 1
              showNotify(this.$toast, this.$t(response.data.body), 'success')
            })
            .catch(error => {
              throwDefaultError(this.$toast, error)
            })
            .finally(() => { this.loading = false })
      }
    },
    unBlockUser(id) {
      this.loading = true

      useJwt.unBlockUser(id)
          .then(response => {
            showNotify(this.$toast, this.$t('Unblocking user successful'), 'success')

            gtmTrackNewEvent('unblockuser', {
              category: 'Profil',
              action: 'UnblockUser',
              label: id
            })

            this.getMoments()
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },

    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;

      const _file = files[0]
      this.fileRaw = _file

      if (_file) {
        // Boyut kontrolü
        if (_file.size > this.fileMaxSize) {
          showNotify(this.$toast, this.$t('File size should be maximum {size}', {size: '100 MB'}))
          return
        }

        // Tip kontrolü
        if (!_file.type.includes('image/') && !_file.type.includes('video/')) {
          showNotify(this.$toast, this.$t('You can only upload photos or videos'))
          return
        }

        this.fileType = _file.type.includes('image/') ? 'image' : 'video'
        this.file = URL.createObjectURL(_file);

        // this.createImage(_file)
      }
    },
    createImage(file) {
      this.file = URL.createObjectURL(file);
    },
  }
}
</script>

<style lang="scss" scoped>
.share-button {
  padding-right: 18px;
}
.moments-post {
  display: flex;
  margin-bottom: 36px;
  flex-direction: column;
  gap: 8px;
  position: relative;

  .moments-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .moments-profile {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .moments-username-follow-button {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 3px 0;
        .moments-username {
          color: #272727;
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
        }
        .moments-follow-button {
          button {
            font-size: 11px;
            color: #000000;
            border: 1px solid #E6E6E6 !important;
            padding: 2px 14px;
          }
        }
      }
      }

    .moments-time {
      font-size: 14px;
      color: #A6A6A6;
      font-weight: 700;
    }
  }
  .moments-body {
    .moments-media {
      position: relative;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      cursor: pointer;

      div {
        height: 360px;
        display: flex;
        justify-content: center;
        background-color: #1e1e1e;

        image {
          width: 100%;
          object-fit: contain !important;
        }
      }

      video {
        object-fit: contain !important;
      }

      svg {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #fff;
        font-size: 20px;
      }
    }
  }
  .moments-footer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    .moments-content {
      padding: 12px 10px;
      font-size: 14px;
      color: #272727;
      font-weight: 300;
    }

    .moments-footer-sub {
      display: flex;
      justify-content: space-between;
      padding: 12px 10px;

      .moments-likes-icon {
        color: #EE0555;
        cursor: pointer;
        font-size: 16px;
      }

      .moments-likes-count {
        color: #A9A9A9;
        font-size: 14px;
        font-weight: 500;
      }

      .moments-footer-menu {
        font-size: 18px;
        color: #A6A6A6;
        cursor: pointer;
      }
    }
  }

  .moments-hr {
    width: 100%;
    height: 1px;
    background-color: #D1D8DD;
    margin-top: 14px;
  }
}

.new-post-modal {
  color: #A6A6A6;

  .new-post-modal-upload {
    width: 100%;
    height: 296px;
    border: 1px solid #D1D8DD;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    gap: 12px;
  }

  .form-accepted {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;

    .custom-checkbox {
      width: 32px;
      height: 32px;
    }
  }

  .new-post-modal-preview {
    width: 100%;
    height: 296px;
    overflow: hidden;
    cursor: pointer;

    video {
      height: 296px;
      object-fit: contain !important;
    }

    img {
      width: 100%;
      object-fit: fill !important;
    }

    svg {
      position: absolute;
      right: 30px;
      top: 24px;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
</style>

<style>
.b-nav-dropdown .dropdown-toggle::after {
  display: none !important;
}
</style>